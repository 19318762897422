$(document).on("turbolinks:load", function() {
  $("#cv_file").change(function() {
    var i = $(this)
      .prev("label")
      .clone();
    var file = $("#cv_file")[0].files[0].name;
    $(this)
      .prev("label")
      .text(file);
  });
});
